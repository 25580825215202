import React, { useState } from 'react';
import { useFormik } from 'formik';
import { TextField, Button, Typography, Box, Stack, InputLabel } from '@mui/material';
import * as Yup from 'yup';
import MainCard from 'components/MainCard';
import { FormattedMessage } from 'react-intl';
import { addSubject, updateSubject } from 'services/subject.service';
import Snackbar from 'utils/Snackbar';
import { useLocation, useNavigate } from 'react-router';
import BackToggle from 'components/third-party/dropzone/BackToggle';

const validationSchema = Yup.object().shape({
  subjectName: Yup.string()
    .min(3, 'Subject Name must be at least 3 characters long')
    .max(30, 'Subject Name must be at most 30 characters long')
    .required('Subject Name is required'),

  subjectCode: Yup.string().optional(), // Optional field

  description: Yup.string().optional() // Optional field
});

const AddSubject = () => {
  const location = useLocation();
  const rowData = location.state?.subject || {};

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const formik: any = useFormik({
    initialValues: {
      subjectName: rowData && rowData?.subjectName ? rowData?.subjectName : '',
      subjectCode: rowData && rowData?.subjectCode ? rowData?.subjectCode : '',
      description: rowData && rowData?.description ? rowData?.description : ''
    },
    validationSchema,
    onSubmit: (values) => {
      if (!rowData?._id) {
        addSubject(values)
          .then((res) => {
            if (res.status === 201) {
              Snackbar('Subject added successfully!!!', 'success');
              navigate(-1);
              setIsButtonDisabled(false);
            } else {
              Snackbar(res.data.message, 'error');
              setIsButtonDisabled(false);
            }
          })
          .catch((err) => {
            setIsButtonDisabled(false);
            if (err?.response?.status === 403) {
              const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
              Snackbar(errorMessage, 'warning');
            } else {
              const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
              Snackbar(errorMessage, 'error');
            }
          });
      } else {
        updateSubject(values, rowData?._id)
          .then((res) => {
            if (res.status === 200) {
              Snackbar('Subject updated successfully!!!', 'success');
              navigate(-1);
              setIsButtonDisabled(false);
            } else {
              Snackbar(res.data.message, 'error');
              setIsButtonDisabled(false);
            }
          })
          .catch((err) => {
            if (err?.response?.status === 403) {
              const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
              Snackbar(errorMessage, 'warning');
            } else {
              const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
              Snackbar(errorMessage, 'error');
            }
            setIsButtonDisabled(false);
          });
      }
    }
  });

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
            <BackToggle />
          </span>
          <span style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5">
                <FormattedMessage id={!rowData?._id ? 'Add Subject' : 'Update Subject'} />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage id={!rowData?._id ? 'Here one can add subject' : 'Here one can update subject'} />
              </Typography>
            </span>
          </span>
        </span>
      }
      content={false}
    >
      <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ p: 1.5 }}>
        <Stack spacing={1.25} sx={{ mb: 2 }}>
          <InputLabel htmlFor="personal-first-name">
            <FormattedMessage id="Subject Name" />
          </InputLabel>
          <TextField
            fullWidth
            margin="normal"
            id="subjectName"
            name="subjectName"
            variant="outlined"
            value={formik.values.subjectName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.subjectName && Boolean(formik.errors.subjectName)}
            helperText={formik.touched.subjectName && formik.errors.subjectName}
          />
        </Stack>
        <Stack spacing={1.25} sx={{ mb: 2 }}>
          <InputLabel htmlFor="personal-first-name">
            <FormattedMessage id="Subject Code (optional)" />
          </InputLabel>
          <TextField
            fullWidth
            margin="normal"
            id="subjectCode"
            name="subjectCode"
            variant="outlined"
            value={formik.values.subjectCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.subjectCode && Boolean(formik.errors.subjectCode)}
            helperText={formik.touched.subjectCode && formik.errors.subjectCode}
          />
        </Stack>
        <Stack spacing={1.25} sx={{ mb: 2 }}>
          <InputLabel htmlFor="personal-first-name">
            <FormattedMessage id="Description (optional)" />
          </InputLabel>
          <TextField
            fullWidth
            margin="normal"
            id="description"
            name="description"
            variant="outlined"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </Stack>
        <Stack direction={'row-reverse'}>
          <Button sx={{ mx: 1 }} color="primary" variant="contained" type="submit" disabled={isButtonDisabled}>
            {!rowData?._id ? 'Add' : 'Update'}
          </Button>
          <Button
            color="error"
            variant="outlined"
            sx={{ mx: 1 }}
            onClick={() => {
              navigate(-1);
            }}
          >
            cancel
          </Button>
        </Stack>
      </Box>
    </MainCard>
  );
};

export default AddSubject;
