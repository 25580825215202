import { Avatar, Card, CardContent, CardHeader, Chip, Fab, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import image from 'assets/images/sapphire_logo.jpg';
import { useState } from 'react';
import { Add, Briefcase, Message, Messages2 } from 'iconsax-react';
import { useLocation, useNavigate } from 'react-router';
import ChatOptionChip from './ChatOptionChip';

const ChatOptions = () => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState<string | null>(null);
  const [messages, setMessages] = useState<Array<{ message: string; type: string }>>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const handleOpen = () => {
    setOpen(true);
    setContact(null);
  };
  const handleChatNavigate = () => {
    navigate('/learner/chat', { state: { type: 'aibot' } });
    setOpen(false);
    setContact('aibot');
  };
  const handleClose = () => {
    setOpen(false);
    setContact(null);
  };
  const handleSetChatMessages = (data: { message: string; type: 'bot' | 'user' }[]) => {
    setMessages((prev) => [...prev, ...data]);
  };
  if (user?._id && user?.type === 'student') {
    return (
      <Stack position={'fixed'} bottom={25} right={25} zIndex={100}>
        {!open && (location.pathname === '/learner/chat' && contact === 'aibot' ? false : true) && (
          <Stack
            aria-label="add"
            onClick={handleOpen}
            sx={{
              cursor: 'pointer',
              animation: 'scale 4s ease-in-out infinite, shake 1s ease-in-out infinite',
              '@keyframes scale': {
                '0%, 100%': {
                  transform: 'scale(1)'
                },
                '50%': {
                  transform: 'scale(1.4)'
                }
              },
              '@keyframes shake': {
                '0%': { transform: 'scale(1) rotate(0deg)' },
                '15%': { transform: 'scale(1.2) rotate(0deg)' },
                '25%': { transform: 'scale(1.2) rotate(25deg)' },
                '50%': { transform: 'scale(1.2) rotate(-25deg)' },
                '60%': { transform: 'scale(1.2) rotate(0deg)' },
                '100%': { transform: 'scale(1) rotate(0deg)' }
              }
            }}
          >
            <Avatar
              variant="square"
              src={image}
              alt="sapphire"
              sx={{ width: '100%', height: 'auto', maxWidth: '70px', borderRadius: '10px' }}
            />
          </Stack>
        )}
        {open && (
          <Card sx={{ zIndex: 99, height: '100%', minHeight: '150px', backgroundColor: '#EAEEF3' }}>
            <CardHeader
              title={<Typography variant="h5">Welcome To Sapphire Lms!</Typography>}
              subheader={<Typography variant="subtitle2">Please Select Option To Communicate</Typography>}
              avatar={
                <Avatar
                  variant="square"
                  src={image}
                  alt="sapphire"
                  sx={{ width: '100%', height: 'auto', maxWidth: '50px', borderRadius: '10px' }}
                />
              }
              sx={{ backgroundColor: '#fff' }}
              action={
                <IconButton onClick={handleClose}>
                  <Add style={{ transform: 'rotate(45deg)' }} />
                </IconButton>
              }
            />

            <CardContent sx={{ height: '100%', maxHeight: '420px', overflowY: 'auto', p: 0, pt: contact === null ? 2 : 1, px: 2 }}>
              {contact === null ? (
                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} maxWidth={{ md: '420px', xs: '320px' }}>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    width={'100%'}
                    height={'100%'}
                    p={1}
                    alignItems={'center'}
                    mx={1}
                    onClick={() => {
                      setContact('faq');
                      setMessages([{ message: 'Thank you for Showing Interest!', type: 'bot' }]);
                    }}
                    sx={{
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease',
                      border: '1px solid',
                      color: theme.palette.primary.main,
                      borderRadius: '10px',
                      '&:active': {
                        transform: 'scale(0.95)' // Scale down on click
                      }
                    }}
                  >
                    <Typography variant="h4">FAQ</Typography>
                    <Message />
                  </Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    p={1}
                    width={'100%'}
                    height={'100%'}
                    alignItems={'center'}
                    disabled={location.pathname === '/learner/chat'}
                    mx={1}
                    onClick={handleChatNavigate}
                    sx={{ cursor: 'pointer', border: '1px solid', color: theme.palette.primary.main, borderRadius: '10px' }}
                  >
                    <Typography variant={'h4'}>AI Chat</Typography>
                    <Messages2 />
                  </Stack>
                </Stack>
              ) : (
                <Stack sx={{ minHeight: '200px', width: 'auto', mt: 0.5, maxHeight: '80%' }}>
                  {messages.map((message) => (
                    <Stack
                      spacing={1.25}
                      direction="row"
                      flexDirection={'column'}
                      // sx={{ marginRight: chat?.text?.split(/\s+/).some((word: string) => word.length > 80) ? '40px' : '0px' }}
                    >
                      <Stack>
                        {message.type === 'bot' && (
                          <Stack direction={'row'} alignItems={'center'}>
                            <Avatar
                              alt={user?.firstName || 'User Name'}
                              src={image}
                              sx={{
                                width: 34,
                                height: '5vh',
                                mr: 2,
                                border: '1px solid',
                                borderColor: theme.palette.primary.main,
                                p: 2,
                                bgcolor: 'transparent',
                                objectFit: 'contain',
                                marginRight: '7px',
                                '& .MuiAvatar-img': {
                                  height: '5vh',
                                  objectFit: 'contain',
                                  width: '88px',
                                  borderRadius: '50%'
                                }
                              }}
                            />
                            <Typography>Bot</Typography>
                          </Stack>
                        )}
                      </Stack>
                      <Grid container>
                        <Grid
                          item
                          xs={message?.type === 'bot' ? 10 : 12}
                          md={message?.type === 'bot' ? 9 : 12}
                          xl={message?.type === 'bot' ? 8 : 12}
                        >
                          <Stack
                            direction="row"
                            justifyContent={message?.type === 'bot' ? 'flex-start' : 'flex-end'}
                            alignItems={message?.type === 'bot' ? 'flex-start' : 'flex-end'}
                            mt={message.type === 'bot' ? 0 : 4}
                          >
                            <Card
                              sx={{
                                display: 'inline-block',
                                bgcolor: theme.palette.primary.lighter,
                                boxShadow: 'none',
                                ml: 1
                              }}
                            >
                              <CardContent sx={{ p: 1.2, pb: '8px !important' }}>{message?.message}</CardContent>
                            </Card>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  ))}
                  <Grid container sx={{ ml: 2, mt: 2 }}>
                    <Grid item xs={10} md={9} xl={8}>
                      <ChatOptionChip setMessages={handleSetChatMessages} />
                    </Grid>
                  </Grid>
                </Stack>
              )}
            </CardContent>
          </Card>
        )}
      </Stack>
    );
  }
  return null;
};

export default ChatOptions;
