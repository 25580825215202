import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import useAuth from 'hooks/useAuth';
import { AttachSquare, CloseCircle, DocumentDownload, Eye, Ticket, Trash } from 'iconsax-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Snackbar from 'utils/Snackbar';
import SkeletonTable from 'components/SkeletonTable';
import CustomTable from 'components/@extended/CustomTable';
import IconButton from 'components/@extended/IconButton';
import { deleteTicket, getStudentTickets, updateTicketStatus } from 'services/ticket.service';
import {
  setticketPageIndex,
  updateticket,
  setticketTableSize,
  setticketTotalCount,
  delticket,
  resetStateticketTable
} from 'store/reducers/ticket';
import { FormattedMessage } from 'react-intl';
import { getFilterListUser } from 'services/user.service';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Avatar from 'components/@extended/Avatar';
import { downloadFile } from 'services/assignment.service';

const RaisedTicketTable = () => {
  const { user }: any = useAuth();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [notFound, setNotFound] = useState(true);
  const [ticketIdToDelete, setExamIdToDelete] = useState<string | null>(null);
  const ticketTypeOptions = ['Billing / Account Support', 'Compliance Inquiry', 'Sale Support', 'Technical Support'];
  const ticketTypeOptionsStudent = ['Result / Result History', 'Profile Update', 'Course Related', 'Exam Related'];
  const permissions = user?.userTypeId?.permission;

  const [filterValues, setFilterValues] = useState<any>({
    search: '',
    FilterTypes: [],
    type: [],
    status: [],
    startDate: null,
    endDate: null
  });
  const getDefaultTotalFilter = (userType: any) => {
    const options = ['type', 'status'];

    if (userType === 'superadmin') {
      return {
        search: '',
        FilterTypes: { val1: options, val2: 'checkbox' }
      };
    } else if (userType === 'admin') {
      const filteredOptions = options.filter((option) => option !== 'organization');
      return {
        search: '',
        FilterTypes: { val1: filteredOptions, val2: 'checkbox' }
      };
    } else if (userType === 'faculty') {
      const filteredOptions = options.filter((option) => option !== 'organization' && option !== 'createdBy');
      return {
        search: '',
        FilterTypes: { val1: filteredOptions, val2: 'checkbox' }
      };
    }

    // Handle other user types or set a default state if needed
    return {
      search: '',
      FilterTypes: { val1: options, val2: 'checkbox' }
    };
  };
  const [totalFilter, setTotalFilter] = useState(getDefaultTotalFilter(user?.type));

  const pageIndex = useSelector((state: any) => state?.ticket)?.pageIndex;
  const size = useSelector((state: any) => state?.ticket)?.size;
  const ticket = useSelector((state: any) => state?.ticket)?.ticket;
  const totalCount = useSelector((state: any) => state?.ticket)?.totalCount;
  const search = useSelector((state: any) => state?.ticket)?.search;

  // const uniqueTypes = ['Billing / Account Support', 'Compliance Inquiry', 'Sale Support', 'Technical Support'];
  const uniqueTypes = user?.type === 'student' ? ticketTypeOptionsStudent : ticketTypeOptions;
  const uniqueStatuses = ['Open', 'Inprogress', 'Resolved', 'Improvement', 'Bug'];

  const openDialogBox = (id: string) => {
    setExamIdToDelete(id);
    setOpenDialog(true);
  };

  const dialogClose = () => {
    setOpenDialog(false);
  };

  const fetchticket = async () => {
    setIsLoading(true);
    let reqData: any = {
      ...(user?.type === 'faculty' || user?.type === 'student' ? { userId: user?._id, organizationId: user?.organizationId?._id } : {}),
      ...(user?.type === 'admin' ? { organizationId: user?.organizationId?._id } : {}),
      skip: pageIndex,
      limit: size,
      type: filterValues?.type,
      status: filterValues?.status
    };
    if (filterValues.search) reqData.search = filterValues.search;
    if (filterValues?.startDate) reqData.startDate = filterValues.startDate;
    if (filterValues?.startDate || filterValues.endDate) reqData.endDate = filterValues.endDate ? filterValues.endDate : new Date();
    getStudentTickets(reqData)
      .then((result) => {
        setIsLoading(false);
        dispatch(updateticket(result?.data?.data?.getTicket));
        dispatch(setticketTotalCount(result?.data?.data.count?.[0]?.count));
        // dispatch(setticketTotalCount(result?.data?.data?.[0]?.count?.[0]?.total));
        if (result?.data?.data?.[0]?.data?.length > 0) setNotFound(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  useEffect(() => {
    fetchticket();
  }, [pageIndex, size, search, filterValues]);

  useEffect(() => {
    return () => {
      dispatch(resetStateticketTable());
    };
  }, [dispatch]);

  const ticketDelete = () => {
    if (ticketIdToDelete) {
      deleteTicket({ supportId: ticketIdToDelete })
        .then((res: any) => {
          if (res.status === 200) {
            Snackbar('Ticket Deleted Successfully!', 'success');
            dispatch(delticket(ticketIdToDelete));
          } else {
            Snackbar('There Was An Error Deleting This ticket!', 'error');
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
            Snackbar(errorMessage, 'warning');
          } else {
            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
            Snackbar(errorMessage, 'error');
          }
        });
    }
    setOpenDialog(false);
  };

  const columns: any = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      ...(user?.type === 'superadmin' || user?.type === 'admin'
        ? [
            {
              Header: 'Raised By',
              accessor: 'createdBy',
              Cell: ({ value }: { value: any }) => {
                return (
                  <Tooltip title={`${value?.firstName} ${value?.lastName}`}>
                    <Box sx={{ textTransform: 'capitalize' }}>
                      <Avatar
                        alt={value?.firstName}
                        size="md"
                        src={
                          value?.userProfilePicId?.path
                            ? process.env.REACT_APP_FILE_URL + value?.userProfilePicId?.path
                            : value?.userProfilePicId?.mimetype && value?.userProfilePicId?.file
                            ? `data:${value?.userProfilePicId?.mimetype};base64,${value?.userProfilePicId?.file}`
                            : ''
                        }
                      />
                    </Box>
                  </Tooltip>
                );
              }
            }
          ]
        : []),
      ...(user?.type === 'superadmin'
        ? [
            {
              Header: 'Organization',
              accessor: 'organizationName',
              Cell: ({ row }: { row: any }) => {
                return row?.original?.organizationId?.name;
              }
            }
          ]
        : []),
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ row }: { row: any }) => <Typography textTransform={'capitalize'}>{row?.original?.type}</Typography>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const [selectedStatus, setSelectedStatus] = useState(row?.original?.status);
          const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

          const statusOptions = ['Open', 'Inprogress', 'Resolved', 'Improvement', 'Bug'];

          const handleChange = (event: any) => {
            const newStatus = event.target.value;
            setOpenConfirmationDialog(true);
            setSelectedStatus(newStatus);
          };

          const handleConfirm = () => {
            const reqData = { supportId: row?.original?._id, organizationId: user?.organizationId?._id, status: selectedStatus };
            updateTicketStatus(reqData)
              .then((res) => {
                if (res.status) {
                  setOpenConfirmationDialog(false);
                  Snackbar('Status changes successfully', 'success');
                }
              })
              .catch((err) => {
                setOpenConfirmationDialog(false);
                Snackbar('Error while changing status', 'warning');
              });
          };

          const handleCancel = () => {
            setSelectedStatus(row?.original?.status);
            setOpenConfirmationDialog(false);
          };

          return (
            <div>
              <TextField
                name="status"
                label="Ticket Status"
                fullWidth
                select
                margin="normal"
                value={selectedStatus}
                onChange={handleChange}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              {/* Confirmation Dialog */}
              <Dialog
                open={openConfirmationDialog}
                onClose={handleCancel}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
                sx={{ textAlign: 'center' }}
              >
                <DialogTitle id="confirmation-dialog-title">Confirm Status Change</DialogTitle>
                <DialogContent>Are you sure you want to change the status to {selectedStatus}?</DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                  <Button onClick={handleCancel} color="error" variant="outlined">
                    Cancel
                  </Button>
                  <Button onClick={handleConfirm} color="primary" autoFocus variant="contained">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          );
        }
      },
      {
        Header: 'View',
        accessor: 'view',
        Cell: ({ row }: any) => {
          const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
          const [deleteDialogue, setDeleteDialogue] = useState(false);
          const [openImage, setOpenImage] = useState(false);

          const handleCancel = () => {
            setOpenConfirmationDialog(false);
          };
          const handleCancelDelete = () => {
            setDeleteDialogue(false);
          };

          const ticketDelete = () => {
            deleteTicket({ supportId: row?.original?._id })
              .then((result) => {
                if (result.status === 200) {
                  handleCancelDelete();
                  Snackbar('Your ticket has been successfully deleted.', 'success');
                } else {
                  Snackbar(result.data.message, 'error');
                }
              })
              .catch((err) => {
                if (err?.response?.status === 403) {
                  const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
                  Snackbar(errorMessage, 'warning');
                } else {
                  const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
                  Snackbar(errorMessage, 'error');
                }
                handleCancelDelete();
              });
          };

          const downloadTheFile: any = async (file: any) => {
            try {
              const response = await downloadFile({ fileId: file._id });
              const contentType = response.headers['content-type'];
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', file?._id ? file?.originalname : file?.filename);
              if (contentType) {
                link.setAttribute('download', `${file?._id ? file?.originalname : file?.filename}`);
              }
              document.body.appendChild(link);
              link.click();
              Snackbar('File Downloaded Successfully.', 'success');
            } catch (error) {
              console.error('Error downloading file: ', error);
              console.log(error);
            }
          };

          return (
            <div>
              <IconButton style={{ backgroundColor: 'transparent' }} onClick={() => setOpenConfirmationDialog(true)}>
                <Eye variant="Bulk" />
              </IconButton>
              <IconButton
                style={{ backgroundColor: 'transparent' }}
                color="error"
                disabled={!permissions?.Tickets?.Delete}
                onClick={() => setDeleteDialogue(true)}
              >
                <Trash variant="Bulk" />
              </IconButton>

              {/* Eye Dialogue */}
              <Dialog
                open={openConfirmationDialog}
                onClose={handleCancel}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
              >
                <Stack direction={'row-reverse'} justifyContent={'space-between'} minWidth={400}>
                  <IconButton onClick={() => setOpenConfirmationDialog(false)} style={{ backgroundColor: 'transparent' }}>
                    <CloseCircle />
                  </IconButton>
                  <DialogTitle id="confirmation-dialog-title">Ticket Detail</DialogTitle>
                </Stack>
                <Divider />
                <Box py={1} px={3}>
                  <Stack spacing={1}>
                    <Typography textTransform={'capitalize'}>
                      <b> Type:</b> {row?.original?.type}
                    </Typography>
                    <Typography>
                      <b> Name:</b> {row?.original?.name}
                    </Typography>
                    <Typography>
                      <b> Description:</b> {row?.original?.description}
                    </Typography>
                    {row?.original?.uploadFile && row?.original?.uploadFile !== null ? (
                      <Typography>
                        <b> File:</b>
                        <Tooltip title="View attached file">
                          <span>
                            <Button
                              onClick={() => setOpenImage(true)}
                              endIcon={<AttachSquare variant="Bulk" />}
                              sx={{ backgroundColor: 'transparent', marginInline: '10px' }}
                            >
                              Open
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title={'Download File'}>
                          <span>
                            <IconButton onClick={() => downloadTheFile(row?.original?.uploadFile)}>
                              <DocumentDownload />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Box>

                <Dialog
                  open={openImage}
                  onClose={handleCancel}
                  aria-labelledby="confirmation-dialog-title"
                  aria-describedby="confirmation-dialog-description"
                  sx={{ justifyContent: 'center' }}
                >
                  <Stack direction={'row-reverse'}>
                    <IconButton onClick={() => setOpenImage(false)} style={{ backgroundColor: 'transparent' }}>
                      <CloseCircle />
                    </IconButton>
                  </Stack>
                  {row?.original?.uploadFile !== null && row?.original?.uploadFile?.mimetype === 'application/pdf' ? (
                    <object
                      data={process.env.REACT_APP_FILE_URL + row?.original?.uploadFile?.path}
                      type="application/pdf"
                      width="100%"
                      height="400px"
                      style={{ borderRadius: '15px', margin: '5px' }}
                    />
                  ) : (
                    <>
                      <img
                        src={process.env.REACT_APP_FILE_URL + row?.original?.uploadFile?.path}
                        alt="image not found"
                        style={{
                          width: '230px',
                          height: '200px',
                          marginTop: '10px',
                          borderRadius: '10px',
                          margin: '5px'
                        }}
                      />
                    </>
                  )}
                </Dialog>
              </Dialog>

              {/* Trash dialogue */}
              <Dialog
                open={deleteDialogue}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box sx={{ p: 1, py: 1.5, textAlign: 'center' }}>
                  <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id="Delete ticket" />
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <FormattedMessage id="Are you sure to delete this ticket?" />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={handleCancelDelete}>
                      <FormattedMessage id="Cancel" />
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        ticketDelete();
                      }}
                      autoFocus
                    >
                      <FormattedMessage id="Delete" />
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>
            </div>
          );
        }
      }
      // {
      //   Header: 'Options',
      //   accessor: 'progress',
      //   Cell: ({ row }: any) => {
      //     console.log('Row', row);
      //     return (
      //       <>
      //         <Stack direction="row" spacing={0}>
      //           <Tooltip title="Edit">
      //             <IconButton
      //               color={'primary'}
      //               // disabled={
      //               //   (user?.type !== 'superadmin' && row.original.status !== 'Open') ||
      //               //   (user?.type !== 'superadmin' && row?.original?.createdBy?._id !== user?._id)
      //               // }
      //               onClick={(e) => {
      //                 navigate(user?.type === 'student' ? '/learner/ticket/edit' : '/ticket/edit', {
      //                   state: { rowData: row.original, status: 'comesToReslve' }
      //                 });
      //               }}
      //             >
      //               <Edit />
      //             </IconButton>
      //           </Tooltip>
      //           {user?.type === 'superadmin' ? (
      //             <Tooltip title="Delete">
      //               <IconButton
      //                 color={'error'}
      //                 onClick={() => {
      //                   openDialogBox(row?.original?._id);
      //                 }}
      //               >
      //                 <Trash />
      //               </IconButton>
      //             </Tooltip>
      //           ) : (
      //             <></>
      //           )}
      //         </Stack>
      //       </>
      //     );
      //   }
      // }
    ],
    [user?.type]
  );
  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(setticketPageIndex(1));
    dispatch(setticketTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    dispatch(setticketPageIndex(page));
  };

  const setModalFilters = (key: string, value: any) => {
    if (key === 'FilterTypes') {
      setFilterValues((prev: any) => {
        const currentValue = prev[key] || [];
        let updatedArray;

        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter((item: any) => item !== value);
        } else {
          updatedArray = [...currentValue, value];
        }

        return {
          ...prev,
          [key]: updatedArray,
          type: key === 'FilterTypes' && updatedArray.includes('type') ? prev.type : '',
          status: key === 'FilterTypes' && updatedArray.includes('status') ? prev.status : ''
        };
      });
    } else if (key === 'type') {
      setFilterValues((prev: any) => {
        const currentValue = prev[key] || [];
        let updatedArray;

        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter((item: any) => item !== value);
        } else {
          updatedArray = [...currentValue, value];
        }

        return {
          ...prev,
          [key]: updatedArray
        };
      });
    } else if (key === 'status') {
      setFilterValues((prev: any) => {
        const currentValue = prev[key] || [];
        let updatedArray;

        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter((item: any) => item !== value);
        } else {
          updatedArray = [...currentValue, value];
        }

        return {
          ...prev,
          [key]: updatedArray
        };
      });
    } else {
      setFilterValues((prev: any) => ({ ...prev, [key]: value }));
    }
    // dispatch(setUserPageIndex(1));
  };

  useEffect(() => {
    if (filterValues.FilterTypes.includes('type')) {
      setTotalFilter((prev: any) => ({ ...prev, type: { val1: uniqueTypes, val2: 'checkbox' } }));
    } else {
      setTotalFilter((prev: any) => {
        const { type: classKey, ...rest } = prev;
        return rest;
      });
    }
    if (filterValues.FilterTypes.includes('status')) {
      setTotalFilter((prev: any) => ({ ...prev, status: { val1: uniqueStatuses, val2: 'checkbox' } }));
    } else {
      setTotalFilter((prev: any) => {
        const { status: classKey, ...rest } = prev;
        return rest;
      });
    }
  }, [filterValues]);

  function capitalizeFirstLetter(str: any) {
    return str
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  return (
    <>
      {/* <MainCard
        content={false}
        title={'Requested Tickets'}
        secondary={
          <Tooltip title={<FormattedMessage id="filter" />}>
            <ModalFilter filterKeywords={totalFilter} onFilterKeywordChange={setModalFilters} count={totalCount} values={filterValues} />
          </Tooltip>
        }
      > */}
      <DndProvider backend={HTML5Backend}>
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ my: 1 }}>
          <Stack display={'flex'} sx={{ mx: 1 }} flexDirection={'row'} flexWrap={'wrap'}>
            {filterValues?.search || filterValues?.status.length > 0 || filterValues?.type.length > 0 || filterValues?.startDate ? (
              <Button
                variant="outlined"
                onClick={() => {
                  setFilterValues({ search: '', FilterTypes: [], type: [], status: [], startDate: null, endDate: null });
                }}
                sx={{ m: 1 }}
              >
                Clear All
              </Button>
            ) : (
              <></>
            )}
            {Object.entries<any>(filterValues).map(
              ([key, values]: [string, string[] | string]) =>
                key !== 'FilterTypes' &&
                values &&
                values?.length > 0 && (
                  <div
                    key={`${key}-box`}
                    style={{
                      border: '1px solid gray',
                      display: 'flex',
                      margin: '2px',
                      borderRadius: '15px',
                      flexDirection: 'row',
                      backgroundColor:
                        key === 'type' && mode !== 'dark' ? '#F4F7FF' : key === 'status' && mode !== 'dark' ? '#F8F9FA' : 'transparent'
                    }}
                  >
                    <Typography mx={1} mt={1.5}>
                      {' '}
                      <b>{capitalizeFirstLetter(key)}:</b>{' '}
                    </Typography>
                    {(Array.isArray(values) ? values : [values]).map(
                      (value: string | Number, index: number) =>
                        value !== undefined &&
                        value !== null && (
                          <Chip
                            key={`${key}-${index}`}
                            sx={{ m: 1 }}
                            label={`${value}`}
                            color={key === 'status' ? 'primary' : 'secondary'}
                            style={{ color: key !== 'status' && mode === 'dark' ? 'black' : 'white' }}
                            onDelete={() => {
                              if (key === 'FilterTypes' || key === 'type' || key === 'status') {
                                setModalFilters(key, value);
                              } else {
                                setModalFilters(key, '');
                              }
                            }}
                          />
                        )
                    )}
                  </div>
                )
            )}
            {filterValues.startDate || filterValues.endDate ? (
              <div
                style={{
                  border: '1px solid gray',
                  margin: '2px',
                  borderRadius: '15px',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <React.Fragment>
                  <Typography mx={1} mt={1.5}>
                    <b>Range:</b>
                  </Typography>
                  <Chip
                    label={`${moment(filterValues.startDate).format('DD-MM-YYYY')} to ${moment(filterValues.endDate || new Date()).format(
                      'DD-MM-YYYY'
                    )}`}
                    color="primary"
                    sx={{ m: 1 }}
                    onDelete={() => {
                      setModalFilters('startDate', null);
                      setModalFilters('endDate', null);
                    }}
                  />
                </React.Fragment>
              </div>
            ) : null}
          </Stack>
        </Stack>
        {isLoading ? (
          <SkeletonTable columns={columns} />
        ) : (
          <CustomTable
            icon={Ticket}
            text={<FormattedMessage id="No Tickets available !!" />}
            columns={columns}
            data={ticket || []}
            sortArray={[]}
            sort={undefined}
            setSortingData={undefined}
            notFound={notFound}
            search={search}
            count={totalCount}
            size={size}
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            handleSizeChange={handleChange}
          />
        )}
      </DndProvider>
      {/* </MainCard> */}

      <Dialog open={openDialog} onClose={dialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete Exam</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Exam ?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={dialogClose}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={ticketDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default RaisedTicketTable;
