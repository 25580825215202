import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Loader2 from 'components/Loader2';
import useAuth from 'hooks/useAuth';
import { addSkillMatrix, assessmentCheck, assessmentQuiz, completeAssessment, generateQuizMatrix } from 'services/quiz.service';
import MainCard from 'components/MainCard';
import AssessmentReport from 'pages/quiz/Report';
import { SearchNormal1 } from 'iconsax-react';
import { useSelector } from 'store';
import { formatDateBasedOnOrgSettings } from 'utils/helper';
import Data404 from 'components/third-party/Data404';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import { Stack } from '@mui/system';
import { getAllSubjects } from 'services/subject.service';
import Snackbar from 'utils/Snackbar';

const QuizView = () => {
  const theme = useTheme();
  const { user, organization }: any = useAuth();
  const permission = user?.userTypeId?.permission;
  const [quizzes, setQuizzes] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMatrix, setOpenMatrix] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const [assessmentId, setAssessmentId] = useState('');
  const [status, setStatus] = useState<any>();
  const [data, setData] = useState<any>();
  const [newstatus, setNewstatus] = useState<any>();
  const [resultOpen, setResultOpen] = useState(false);
  const [reLoading, setReLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [search, setSearch] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [difficulty, setDifficulty] = useState('');
  const [subjectData, setSubjectData] = useState([]);
  const [quizData, setQuizData] = useState([]);
  const [subjectName, setSubjectName] = useState('');

  const handleDifficultyChange = (event: any) => {
    setDifficulty(event.target.value);
  };
  const getSubjectData = () => {
    getAllSubjects({ organizationId: organization?._id, limit: 100, skip: 1 })
      .then((res) => {
        if (res.status === 200) {
          setSubjectData(res?.data?.data?.data);
        } else {
          Snackbar(res.data.message, 'error');
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  const createSkillMatrix = () => {
    addSkillMatrix({
      classroomId: user?.classRoomId?._id,
      subjectId: subjectName,
      difficulty: difficulty
    })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          const quizId = res?.data?.data?._id;
          generateQuizMatrix({
            quizId: quizId,
            difficulty: difficulty
          })
            .then((additionalRes) => {
              if (additionalRes.status === 201 || additionalRes.status === 200) {
                setQuizData(additionalRes?.data?.data);
                const rowData = {
                  ...additionalRes?.data?.data,
                  quizId: quizId,
                  difficulty: difficulty
                };
                if (rowData?.questions?.length > 0) {
                  navigate('/learner/quiz/skilltest', { state: { rowData } });
                } else Snackbar("There's no Questions in the Subject", 'warning');
              } else {
                Snackbar(additionalRes.data.message, 'error');
              }
            })
            .catch((error) => {
              Snackbar('Failed to fetch additional data', 'error');
            });
        } else {
          Snackbar(res.data.message, 'error');
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  const handleChangeSubject = (event: any) => {
    const selectedValue = event.target.value;
    setSubjectName(selectedValue);
  };
  const handleClear = () => {};
  const getBorderColor = (level: string) => {
    switch (level) {
      case 'easy':
        return theme.palette.primary.main;
      case 'medium':
        return theme.palette.warning.main;
      case 'difficult':
        return theme.palette.error.main;
      default:
        return theme.palette.secondary.main;
    }
  };

  useEffect(() => {
    if (search) {
      setIsLoading(true);
      setNoDataFound(false);
      assessmentQuiz({ orgId: user?.organizationId?._id, type: 'quiz', classRoomId: user?.classRoomId?._id, search: searchQuery }).then(
        (result) => {
          setQuizzes(result.data.data.updatedData);
          if (result.data.data.updatedData?.length === 0) {
            setNoDataFound(true);
          }
          setIsLoading(false);
        }
      );
      setSearch(false);
    }
  }, [search]);

  const handleSearchButtonClick = () => {
    setSearch(true);
  };

  useEffect(() => {
    if (searchQuery === '') {
      setSearchQuery(undefined);
      setSearch(true);
    }
  }, [searchQuery]);

  const handleOpen = (statusCode: number, quizId: string) => {
    setAssessmentId(quizId);
    if (statusCode === 208) {
      setModalContent(false);
    } else if (statusCode === 202) {
      setModalContent(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModalContent(false);
  };
  const handleMatrixOpen = () => {
    setOpenMatrix(true);
    getSubjectData();
  };
  const handleMatrixClose = () => {
    setOpenMatrix(false);
  };
  function formatDuration(durationInSeconds: any) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours}H:${minutes}M`;
  }

  const settings = useSelector((state: any) => state?.organization)?.settings?.locale;

  const [ref, inView] = useInView({
    // triggerOnce: true,
    threshold: 0
  });
  const [ref2, inView2] = useInView({
    // triggerOnce: true,
    threshold: 0
  });

  return (
    <>
      <MainCard
        title={
          <Stack display={'flex'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="h5">
              <FormattedMessage id={'Question List'} />
            </Typography>
            <Button
              onClick={() => {
                handleMatrixOpen();
              }}
              variant="contained"
              sx={{
                backgroundColor: '#13AC9E',
                p: 0.9,
                '&:hover': {
                  backgroundColor: '#13AC9E',
                  opacity: 0.8
                }
              }}
            >
              Quiz Matrix
            </Button>
          </Stack>
        }
      >
        <Grid container spacing={2} mb={2} alignItems="center">
          <Grid item xs={12} sm={7} md={8} lg={9} ref={ref}>
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 0 }}
              transition={{ duration: 0.7 }}
            >
              <Link to={user?.type === 'student' ? '/learner/quiz/resulthistory' : '/quiz/resulthistory'}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#13AC9E',
                    p: 0.9,
                    '&:hover': {
                      backgroundColor: '#13AC9E',
                      opacity: 0.8
                    }
                  }}
                >
                  See Result History
                </Button>
              </Link>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Grid container spacing={0.5} alignItems="center">
              <Grid item xs={9} sm={10} md={10} lg={10} ref={ref}>
                <motion.div
                  initial={{ opacity: 0, x: 30 }}
                  animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <TextField
                    label="Search Quiz"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery || ''}
                    // onChange={(e) => setSearchQuery(e.target.value)}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Type to search..."
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#13AC9E' // Border color
                        },
                        '&:hover fieldset': {
                          borderColor: '#13AC9E' // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#13AC9E' // Border color when focused
                        }
                      },
                      '& .MuiInputLabel-root': {
                        color: '#13AC9E' // Label color
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#13AC9E' // Primary color when focused
                      },
                      '& .MuiInputBase-input': {
                        color: '#000' // Text color inside input
                      }
                    }}
                  />
                </motion.div>
              </Grid>
              <Grid item xs={3} sm={2} md={2} lg={2} ref={ref}>
                <motion.div
                  initial={{ opacity: 0, x: 30 }}
                  animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <Tooltip title="Search">
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!searchQuery || searchQuery.trim() === ''}
                      sx={{
                        p: '9px',
                        minWidth: 'auto',
                        mt: 1,
                        color: '#13AC9E',
                        borderColor: '#13AC9E',
                        '&:hover': {
                          color: '#13AC9E',
                          borderColor: '#13AC9E',
                          backgroundColor: 'rgba(19, 172, 158, 0.1)' // Optional: Light background color on hover
                        }
                      }}
                      onClick={handleSearchButtonClick}
                    >
                      <SearchNormal1 />
                    </Button>
                  </Tooltip>
                </motion.div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isLoading ? (
          <Loader2 />
        ) : noDataFound ? (
          <Data404 title="No Quiz Found." />
        ) : (
          <div style={{ position: 'relative' }} ref={ref2}>
            {disabled && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  // background: 'rgba(10, 10, 10, 0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1,
                  marginTop: '12px'
                }}
              >
                <Loader2 />
              </div>
            )}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={inView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Grid container spacing={2}>
                {quizzes &&
                  quizzes.length > 0 &&
                  quizzes?.map((quiz: any, index: any) => (
                    <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
                      <Card
                        variant="outlined"
                        sx={{
                          height: '100%',
                          overflow: 'hidden',
                          border: `1px solid ${getBorderColor(quiz.level)}`,
                          boxShadow: theme.shadows[2],
                          filter: disabled ? 'grayscale(100%) blur(3px)' : 'none'
                        }}
                      >
                        <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                              <Typography variant="h5" component="div">
                                {quiz.name}
                              </Typography>
                              <Typography variant="body2" component="div">
                                Added: {formatDateBasedOnOrgSettings(quiz?.created_at, organization)}
                              </Typography>
                            </div>
                            <div>
                              {quiz.reSubmit ? (
                                <Chip label="Multiple Attempts" color="primary" size="small" sx={{ fontSize: '10px' }} />
                              ) : (
                                <Chip label="Single Attempt" color="warning" size="small" sx={{ fontSize: '10px' }} />
                              )}
                            </div>
                          </div>
                          <Divider sx={{ marginY: 2 }} />
                          <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                            Level: {quiz.level}
                          </Typography>
                          <Typography variant="h6" component="div">
                            Duration: {quiz.duration === '0' ? 'No limit' : formatDuration(parseInt(quiz.duration, 10))}
                          </Typography>
                          <Typography variant="h6">Total Questions: {quiz.questions.length}</Typography>
                          <div style={{ flexGrow: 1 }}></div>
                          <Box mt={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={async () => {
                                setDisabled(true);
                                var response = await assessmentCheck({ userId: user?._id, assessmentId: quiz._id }).then((result) => {
                                  if (result.status === 208 || result.status === 202) {
                                    setDisabled(false);
                                    handleOpen(result.status, quiz._id);
                                  } else {
                                    setDisabled(false);
                                    navigate(user?.type === 'student' ? '/learner/quiz/test' : '/quiz/test', {
                                      state: { assessmentId: quiz._id, location: location?.pathname }
                                    });
                                  }
                                  setStatus(result.data);
                                  return result;
                                });
                                setNewstatus(response.status);
                              }}
                              disabled={!permission?.Quiz?.Attempt}
                            >
                              START QUIZ
                            </Button>
                            {!quiz.reSubmit && (
                              <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                sx={{ mt: 1, cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(user?.type === 'student' ? '/learner/leaderboard' : '/quiz/leaderboard', {
                                    state: { id: quiz._id }
                                  })
                                }
                                disabled={!permission?.Quiz?.LeaderBoard}
                              >
                                VIEW LEADERBOARD
                              </Button>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </motion.div>
          </div>
        )}
        <Dialog
          open={openMatrix}
          onClose={handleMatrixClose}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="dialog-title">Quiz Matrix</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ minWidth: '100px' }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="type">Select Difficulty level</InputLabel>
                  <Select fullWidth id="type" name="type" value={difficulty} onChange={handleDifficultyChange}>
                    <MenuItem value="easy">Easy</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="hard">Hard</MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="subjectName">Subject:</InputLabel>
                  <Select
                    fullWidth
                    id="subjectName"
                    value={subjectName}
                    name="subjectName"
                    onChange={handleChangeSubject}
                    MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} // Customize dropdown menu properties
                  >
                    {subjectData?.map((item: any) => (
                      <MenuItem key={item?._id} value={item?._id}>
                        {item?.subjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleMatrixClose}>
              Close
            </Button>
            <Button
              variant="contained"
              disabled={!difficulty || !subjectName}
              color="primary"
              onClick={async () => {
                handleClose();
                createSkillMatrix();
              }}
            >
              Give Exam Matrix
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="dialog-title">Assessment Completed</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="dialog-description">
              {modalContent
                ? 'You Have Already Submitted This Assessment.\nDo you want to re-attempt the exam?'
                : 'You Have Already Completed This Assessment!!'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>
              Close
            </Button>
            {modalContent && (
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  handleClose();
                  navigate(user?.type === 'student' ? '/learner/quiz/test' : '/quiz/test', { state: { assessmentId } });
                  if (newstatus !== 200) {
                    await completeAssessment({
                      assessmentId: status?.data?.assessmentId,
                      completedAssessmentId: status?.data?._id
                    });
                  }
                }}
              >
                Re-Attempt
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                handleClose();
                setResultOpen(true);
                setReLoading(true);
                const response = await assessmentCheck({ userId: user?._id, assessmentId: assessmentId });
                setData(response?.data);
                setReLoading(false);
              }}
            >
              See Result
            </Button>
          </DialogActions>
        </Dialog>
      </MainCard>
      {reLoading ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Loader2 />
        </div>
      ) : (
        <AssessmentReport
          open={resultOpen}
          reportcard={
            data?.data
              ? {
                  assessmentId: data?.data?.assessmentId,
                  attempt: data?.data?.reSubmit,
                  fromQuiz: false,
                  completedAssessmentId: data?.data?._id,
                  report: {
                    correctAnswer: data?.data?.correctAnswer,
                    duration: data?.data?.duration,
                    inCorrectAnswer: data?.data?.inCorrectAnswer,
                    message: data?.data?.result === 'pass' ? 'Congratulation, you cleared the test.' : 'Sorry, Better luck next time.',
                    notGivenAnswer: data?.data?.notGivenAnswer,
                    status: data?.data?.result,
                    userScore: data?.data?.marks
                  }
                }
              : {}
          }
          status={false}
          handleClear={handleClear}
        />
      )}
    </>
  );
};

export default QuizView;
