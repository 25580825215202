import React, { createContext, useContext, useState, ReactNode } from 'react';

// Create a context
const TabContext = createContext<any>(null);

interface TabProviderProps {
  children: ReactNode;
}

// Create a provider for the context
export const TabProvider = ({ children }: TabProviderProps) => {
  const types = [
    'banner',
    'whyUs',
    'features',
    'tools',
    'experience',
    'faq',
    'useCase',
    'clients',
    'elevateLearning',
    'getInTouch',
    'featureCards',
    'testimonials'
  ];
  const [tabValue, setTabValue] = useState(0);
  console.log('tabValuesss inContext', tabValue);
  const handleTabChange = (newValue: any) => {
    if (typeof newValue === 'number') {
      setTabValue(newValue);
      console.log('Tab value is a number:', newValue);
    } else if (typeof newValue === 'string') {
      console.log('Tab value is a String:', newValue);
      const index = types.indexOf(newValue);
      console.log('Tab value is converted to:', index);

      if (index !== -1) {
        setTabValue(index);
      }
    } else {
      console.error('Unsupported type for tab value:', newValue);
    }
  };

  return <TabContext.Provider value={{ tabValue, handleTabChange }}>{children}</TabContext.Provider>;
};

// Create a custom hook to use the TabContext
export const useTabContext = () => {
  return useContext(TabContext);
};
