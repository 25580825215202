import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import useAuth from 'hooks/useAuth';
import { AddCircle, Edit, TickCircle, Ticket, Trash } from 'iconsax-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Snackbar from 'utils/Snackbar';
import MainCard from 'components/MainCard';
import SkeletonTable from 'components/SkeletonTable';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTable from 'components/@extended/CustomTable';
import IconButton from 'components/@extended/IconButton';
import { deleteTicket, getStudentTickets } from 'services/ticket.service';
import {
  setticketPageIndex,
  updateticket,
  setticketTableSize,
  setticketTotalCount,
  delticket,
  resetStateticketTable
} from 'store/reducers/ticket';
import { FormattedMessage } from 'react-intl';
import ModalFilter from 'components/ModalFilters';
import { getFilterListUser } from 'services/user.service';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Avatar from 'components/@extended/Avatar';
import useContemp from 'hooks/useContemp';

const TicketTable = () => {
  const { user }: any = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [notFound, setNotFound] = useState(true);
  const [ticketIdToDelete, setExamIdToDelete] = useState<string | null>(null);
  const [createdByData, setCreatedByData] = useState([]);
  const ticketTypeOptions = ['Billing / Account Support', 'Compliance Inquiry', 'Sale Support', 'Technical Support'];
  const ticketTypeOptionsStudent = ['result-history', 'profile-update', 'course-related', 'exam-related'];
  const { setData, prevStoredData } = useContemp();
  const permissions = user?.userTypeId?.permission;

  useEffect(() => {
    getFilterListUser({ orgId: user?.organizationId?._id }).then((res) => {
      if (res.status === 200) {
        setCreatedByData(res?.data?.data);
      }
    });
  }, []);

  const [filterValues, setFilterValues] = useState<any>({
    search: '',
    FilterTypes: [],
    type: [],
    status: [],
    startDate: null,
    endDate: null
  });
  const getDefaultTotalFilter = (userType: any) => {
    const options = ['type', 'status'];

    if (userType === 'superadmin') {
      return {
        search: '',
        FilterTypes: { val1: options, val2: 'checkbox' }
      };
    } else if (userType === 'admin') {
      const filteredOptions = options.filter((option) => option !== 'organization');
      return {
        search: '',
        FilterTypes: { val1: filteredOptions, val2: 'checkbox' }
      };
    } else if (userType === 'faculty') {
      const filteredOptions = options.filter((option) => option !== 'organization' && option !== 'createdBy');
      return {
        search: '',
        FilterTypes: { val1: filteredOptions, val2: 'checkbox' }
      };
    }

    // Handle other user types or set a default state if needed
    return {
      search: '',
      FilterTypes: { val1: options, val2: 'checkbox' }
    };
  };
  const [totalFilter, setTotalFilter] = useState(getDefaultTotalFilter(user?.type));

  const pageIndex = useSelector((state: any) => state?.ticket)?.pageIndex;
  const size = useSelector((state: any) => state?.ticket)?.size;
  const ticket = useSelector((state: any) => state?.ticket)?.ticket;
  const totalCount = useSelector((state: any) => state?.ticket)?.totalCount || 0;
  const search = useSelector((state: any) => state?.ticket)?.search;

  // const uniqueTypes = ['Billing / Account Support', 'Compliance Inquiry', 'Sale Support', 'Technical Support'];
  const uniqueTypes = user?.type === 'student' ? ticketTypeOptionsStudent : ticketTypeOptions;
  const uniqueStatuses = ['Open', 'Inprogress', 'Resolved', 'Improvement', 'Bug'];

  const openDialogBox = (id: string) => {
    setExamIdToDelete(id);
    setOpenDialog(true);
  };

  const dialogClose = () => {
    setOpenDialog(false);
  };

  const fetchticket = async () => {
    setIsLoading(true);
    let reqData: any = {
      ...(user?.type === 'faculty' || user?.type === 'student' ? { userId: user?._id, organizationId: user?.organizationId?._id } : {}),
      ...(user?.type === 'admin' ? { userId: user?._id, organizationId: user?.organizationId?._id } : {}),
      skip: pageIndex,
      limit: size,
      type: filterValues?.type,
      status: filterValues?.status
    };
    if (filterValues.search) reqData.search = filterValues.search;
    if (filterValues?.startDate) reqData.startDate = filterValues.startDate;
    if (filterValues?.startDate || filterValues.endDate) reqData.endDate = filterValues.endDate ? filterValues.endDate : new Date();
    const APIname = getStudentTickets;
    APIname(reqData)
      .then((result) => {
        setIsLoading(false);
        const response = result?.data?.data?.getTicket;
        const totalCount = result?.data?.data?.count?.[0]?.count;
        dispatch(updateticket(response));
        dispatch(setticketTotalCount(totalCount));
        if (result?.data?.data?.[0]?.data?.length > 0) setNotFound(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  useEffect(() => {
    fetchticket();
  }, [pageIndex, size, search, filterValues]);

  useEffect(() => {
    if (typeof prevStoredData?.[pathname]?.pageIndex === 'number') dispatch(setticketPageIndex(prevStoredData?.[pathname]?.pageIndex));
    if (typeof prevStoredData?.[pathname]?.size === 'number') dispatch(setticketTableSize(prevStoredData?.[pathname]?.size));
    return () => {
      dispatch(resetStateticketTable());
    };
  }, [dispatch]);

  console.log('permissions', permissions)
  const ticketDelete = () => {
    if (ticketIdToDelete) {
      deleteTicket({ supportId: ticketIdToDelete })
        .then((res: any) => {
          if (res.status === 200) {
            Snackbar('Ticket Deleted Successfully!', 'success');
            dispatch(delticket(ticketIdToDelete));
          } else {
            Snackbar('There Was An Error Deleting This ticket!', 'error');
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
            Snackbar(errorMessage, 'warning');
          } else {
            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
            Snackbar(errorMessage, 'error');
          }
        });
    }
    setOpenDialog(false);
  };

  const columns: any = useMemo(
    () => [
      {
        Header: 'Ticket Name',
        accessor: 'name'
      },
      ...(user?.type === 'superadmin' || user?.type === 'admin'
        ? [
            {
              Header: 'Created By',
              accessor: 'createdBy',
              Cell: ({ value }: { value: any }) => {
                return (
                  <Tooltip title={`${value?.firstName} ${value?.lastName}`}>
                    <Box sx={{ textTransform: 'capitalize' }}>
                      <Avatar
                        alt={value?.firstName}
                        size="md"
                        src={
                          value?.userProfilePicId?.path
                            ? process.env.REACT_APP_FILE_URL + value?.userProfilePicId?.path
                            : value?.userProfilePicId?.mimetype && value?.userProfilePicId?.file
                            ? `data:${value?.userProfilePicId?.mimetype};base64,${value?.userProfilePicId?.file}`
                            : ''
                        }
                      />
                    </Box>
                  </Tooltip>
                );
              }
            }
          ]
        : []),
      ...(user?.type === 'superadmin'
        ? [
            {
              Header: 'Organization Name',
              accessor: 'organizationName',
              Cell: ({ row }: { row: any }) => {
                return row.original?.organizationId?.name;
              }
            }
          ]
        : []),
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },

      {
        Header: 'Options',
        accessor: 'progress',
        Cell: ({ row }: any) => {
          return (
            <>
              <Stack direction="row" spacing={0}>
                {row.original.status === 'Resolved' ? (
                  <IconButton
                    color="success"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    <TickCircle variant="Bulk" />
                  </IconButton>
                ) : (
                  <Tooltip title="Edit">
                    <span>
                      <IconButton
                        color={'primary'}
                        disabled={
                          (user?.type !== 'superadmin' && row.original.status !== 'Open') ||
                          (user?.type !== 'superadmin' && row?.original?.createdBy?._id !== user?._id) ||
                          !permissions?.Tickets?.Update
                        }
                        onClick={(e) => {
                          setData({
                            [pathname]: { size, pageIndex, gotoUrl: user?.type === 'student' ? '/learner/ticket/edit' : '/ticket/edit' }
                          });
                          navigate(user?.type === 'student' ? '/learner/ticket/edit' : '/ticket/edit', {
                            state: { rowData: row.original }
                          });
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {user?.type === 'superadmin' ? (
                  <Tooltip title="Delete">
                    <span>
                      <IconButton
                        color={'error'}
                        disabled={!permissions?.Tickets?.Delete}
                        onClick={() => {
                          openDialogBox(row?.original?._id);
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Stack>
            </>
          );
        }
      }
    ],
    [user?.type]
  );
  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(setticketPageIndex(1));
    dispatch(setticketTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    dispatch(setticketPageIndex(page));
  };

  const setModalFilters = (key: string, value: any) => {
    if (key === 'FilterTypes') {
      setFilterValues((prev: any) => {
        const currentValue = prev[key] || [];
        let updatedArray;

        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter((item: any) => item !== value);
        } else {
          updatedArray = [...currentValue, value];
        }

        return {
          ...prev,
          [key]: updatedArray,
          type: key === 'FilterTypes' && updatedArray.includes('type') ? prev.type : '',
          status: key === 'FilterTypes' && updatedArray.includes('status') ? prev.status : ''
        };
      });
    } else if (key === 'type') {
      setFilterValues((prev: any) => {
        const currentValue = prev[key] || [];
        let updatedArray;

        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter((item: any) => item !== value);
        } else {
          updatedArray = [...currentValue, value];
        }

        return {
          ...prev,
          [key]: updatedArray
        };
      });
    } else if (key === 'status') {
      setFilterValues((prev: any) => {
        const currentValue = prev[key] || [];
        let updatedArray;

        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter((item: any) => item !== value);
        } else {
          updatedArray = [...currentValue, value];
        }

        return {
          ...prev,
          [key]: updatedArray
        };
      });
    } else {
      setFilterValues((prev: any) => ({ ...prev, [key]: value }));
    }
    // dispatch(setUserPageIndex(1));
  };

  useEffect(() => {
    if (filterValues.FilterTypes.includes('type')) {
      setTotalFilter((prev: any) => ({ ...prev, type: { val1: uniqueTypes, val2: 'checkbox' } }));
    } else {
      setTotalFilter((prev: any) => {
        const { type: classKey, ...rest } = prev;
        return rest;
      });
    }
    if (filterValues.FilterTypes.includes('status')) {
      setTotalFilter((prev: any) => ({ ...prev, status: { val1: uniqueStatuses, val2: 'checkbox' } }));
    } else {
      setTotalFilter((prev: any) => {
        const { status: classKey, ...rest } = prev;
        return rest;
      });
    }
  }, [filterValues]);

  function capitalizeFirstLetter(str: any) {
    return str
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const isLearnerTicketPath = location.pathname === '/learner/ticket';

  return (
    <>
      <MainCard
        title={isLearnerTicketPath ? 'My Tickets' : undefined}
        content={false}
        sx={{ borderRadius: isLearnerTicketPath ? 1.5 : 0 }}
        secondary={
          <Stack direction={'row'} spacing={2} pr={2} alignItems={'center'} display={'flex'}>
            <span>
              <ModalFilter filterKeywords={totalFilter} onFilterKeywordChange={setModalFilters} count={totalCount} values={filterValues} />
            </span>
            <Tooltip title="Add Ticket">
              <span>
                <IconButton
                  variant="dashed"
                  color="primary"
                  onClick={() => {
                    navigate(user?.type === 'student' ? '/learner/ticket/add' : '/ticket/add');
                  }}
                >
                  <AddCircle />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        }
      >
        <DndProvider backend={HTML5Backend}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ my: 1 }}>
            <Stack display={'flex'} sx={{ mx: 1 }} flexDirection={'row'} flexWrap={'wrap'}>
              {filterValues?.search || filterValues?.status.length > 0 || filterValues?.type.length > 0 || filterValues?.startDate ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setFilterValues({ search: '', FilterTypes: [], type: [], status: [], startDate: null, endDate: null });
                  }}
                  sx={{ m: 1 }}
                >
                  Clear All
                </Button>
              ) : (
                <></>
              )}
              {Object.entries<any>(filterValues).map(
                ([key, values]: [string, string[] | string]) =>
                  key !== 'FilterTypes' &&
                  values &&
                  values?.length > 0 && (
                    <div
                      key={`${key}-box`}
                      style={{
                        border: '1px solid gray',
                        display: 'flex',
                        margin: '2px',
                        borderRadius: '15px',
                        flexDirection: 'row',
                        backgroundColor:
                          key === 'type' && mode !== 'dark' ? '#F4F7FF' : key === 'status' && mode !== 'dark' ? '#F8F9FA' : 'transparent'
                      }}
                    >
                      <Typography mx={1} mt={1.5}>
                        {' '}
                        <b>{capitalizeFirstLetter(key)}:</b>{' '}
                      </Typography>
                      {(Array.isArray(values) ? values : [values]).map(
                        (value: string | Number, index: number) =>
                          value !== undefined &&
                          value !== null && (
                            <Chip
                              key={`${key}-${index}`}
                              sx={{ m: 1 }}
                              label={`${value}`}
                              color={key === 'status' ? 'primary' : 'secondary'}
                              style={{ color: key !== 'status' && mode === 'dark' ? 'black' : 'white' }}
                              onDelete={() => {
                                if (key === 'FilterTypes' || key === 'type' || key === 'status') {
                                  setModalFilters(key, value);
                                } else {
                                  setModalFilters(key, '');
                                }
                              }}
                            />
                          )
                      )}
                    </div>
                  )
              )}
              {filterValues.startDate || filterValues.endDate ? (
                <div
                  style={{
                    border: '1px solid gray',
                    margin: '2px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <React.Fragment>
                    <Typography mx={1} mt={1.5}>
                      <b>Range:</b>
                    </Typography>
                    <Chip
                      label={`${moment(filterValues.startDate).format('DD-MM-YYYY')} to ${moment(filterValues.endDate || new Date()).format(
                        'DD-MM-YYYY'
                      )}`}
                      color="primary"
                      sx={{ m: 1 }}
                      onDelete={() => {
                        setModalFilters('startDate', null);
                        setModalFilters('endDate', null);
                      }}
                    />
                  </React.Fragment>
                </div>
              ) : null}
            </Stack>
          </Stack>
          {isLoading ? (
            <SkeletonTable columns={columns} />
          ) : (
            <CustomTable
              icon={Ticket}
              text={<FormattedMessage id="No Tickets available, Add Ticket from here !!" />}
              link="/ticket/add"
              columns={columns}
              data={ticket || []}
              sortArray={[]}
              disableButton={!permissions?.Tickets?.Create}
              sort={undefined}
              setSortingData={undefined}
              notFound={notFound}
              search={search}
              count={totalCount}
              size={size}
              pageIndex={pageIndex}
              handlePageChange={handlePageChange}
              handleSizeChange={handleChange}
            />
          )}
        </DndProvider>
      </MainCard>

      <Dialog open={openDialog} onClose={dialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete Ticket</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Ticket ?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={dialogClose}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={ticketDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default TicketTable;
