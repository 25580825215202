import { useState } from "react";
import { initializeFirebaseMessaging, fcmToken } from '../utils/firebase';
import useAuth from "./useAuth";
import { updateFCMToken } from "services/user.service";

const usePushNotification = () => {
    const { user } = useAuth()
    const [notificationPermissionStatus, setNotificationPermissionStatus] = useState<any>()
console.log(user?.userTypeId?.permission?.PushNotificationPermission?.Update)
    const requestNotificationPermission = async (): Promise<any> => {
        if(user?.userTypeId?.permission?.PushNotificationPermission?.Update?user?.pushNotification:true){
            try {
                const status = await Notification.requestPermission();
                setNotificationPermissionStatus(status)
                return status
            } catch (error) {
                console.error('Error requesting notification permission:', error);
                throw error;
            }
        }
    };

    const getFcmToken = async (pushVal?: boolean) => {
        const isNotificationGranted = notificationPermissionStatus === 'granted';
        const canReceivePushNotifications = user?.userTypeId?.permission?.PushNotificationPermission?.Update ? user?.pushNotification : true;

        if ((isNotificationGranted && canReceivePushNotifications) || pushVal) {

            initializeFirebaseMessaging().then(async() => {
                if (fcmToken) {
                    console.log('Firebase Messaging Initialized');
                    console.log('FCM Token:', fcmToken);
                    if (user?.pushNotification || pushVal) await updateFCMToken({ webFcmToken: fcmToken });
                } else {
                    getFcmToken()
                    console.log('Failed to retrieve FCM Token.');
                }
            });
        }
    }

    return {requestNotificationPermission, getFcmToken, notificationPermissionStatus}
};

export default usePushNotification;
