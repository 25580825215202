import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: [],
  preferences: {
    count: 1,
    options: 4,
    type: 'mcq',
    multiOptions: 'mcq',
    optionType: 'single',
    selectedLanguage: 'text',
    levels: 'easy',
    classes: [],
    subject: '',
    classType: '',
    classRoomId: '',
    semester: '',
    streamName: '',
    otherName: ''
  },
  questionEdit: false
};

const addQuestionsBank = createSlice({
  name: 'addQuestionsBank',
  initialState,
  reducers: {
    addquestions(state: any, action) {
      console.log('action.payload', action.payload)
      state.questions = action.payload;
    },
    updatequestions(state: any, action) {
      const existingIndex = state.questions.findIndex((question: any) => question._id === action.payload._id);
      if (existingIndex !== -1) {
        state.questions[existingIndex] = action.payload;
      } else {
        state.questions = [...state.questions, action.payload];
      }
    },
    deletequestion(state: any, action) {
      state.questions = state.questions.filter((question: any) => question._id !== action.payload);
    },
    addPreferences(state: any, action) {
      state.preferences = action.payload;
    },
    updatePreference(state, action) {
      const { payload } = action;
      if (payload && typeof payload === 'object') {
        state.preferences = { ...state.preferences, ...payload };
      }
      // return { ...state };
    },
    editQuestion(state: any, action) {
      if (!action.payload) {
        state.preferences = initialState.preferences;
      }
      state.questionEdit = action.payload;
    }
  }
});

export const { addquestions, updatequestions, deletequestion, addPreferences, updatePreference, editQuestion } = addQuestionsBank.actions;
export default addQuestionsBank.reducer;
