import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Box,
  TextField,
  FormControl,
  Typography,
  Autocomplete,
  IconButton,
  Chip,
  FormHelperText,
  Select,
  MenuItem,
  FormLabel
} from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Snackbar from 'utils/Snackbar';
import useAuth from 'hooks/useAuth';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import * as Iconsax from 'iconsax-react';
import React from 'react';
import { Add, AddCircle, BoxRemove, MinusCirlce } from 'iconsax-react';
import { addContent } from 'services/landingPageManagement.service';
import { editContent } from 'services/landingPageManagement.service';
import UploadAvatar from 'components/third-party/dropzone/Avatar';

const validationSchema = yup.object({
  //   type: yup.string().required('Type is required'),
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  pageType: yup.string().required('pageType is required'),
  files: yup
    .mixed()
    .required('image is required')
    .test('fileType', 'Only JPG, PNG, JPEG, and GIF files are allowed', (value) => {
      if (!value || value.length === 0) return false;
      const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
      return allowedFileTypes.includes(value.type);
    })
});

function FeaturesCards(data: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { organization } = useAuth();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const existingImage = rowData?.image ? process.env.REACT_APP_FILE_URL + rowData?.image?.path : null;

  const formik = useFormik({
    initialValues: {
      title: rowData?.title || '',
      description: rowData?.description || '',
      pageType: rowData?.type ? rowData?.type : undefined,
      files: rowData ? rowData.image : []
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('type', values.pageType);
      rowData && formData.append(`id`, rowData?._id?.toString());
      if (formik.values?.files?.length !== 0) {
        formData.append('image', formik.values.files);
      }

      serviceMethod(formData, 'featureCards')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  console.log('rowData', rowData);
  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Content' : 'Add Content'}</span>
        </span>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="pageType">Page Type</InputLabel>
                <Select
                  fullWidth
                  id="pageType"
                  name="pageType"
                  value={formik.values.pageType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pageType && Boolean(formik.errors.pageType)}
                  displayEmpty
                  sx={{
                    backgroundColor: '#F6F6F9', // You can style it as per your preference
                    borderRadius: '4px'
                  }}
                >
                  <MenuItem value="" disabled>
                    Select page type
                  </MenuItem>

                  <MenuItem value="student">Student</MenuItem>
                  <MenuItem value="faculty">faculty</MenuItem>
                </Select>
                {formik.touched.pageType && formik.errors.pageType && (
                  <FormLabel error sx={{ color: 'red', mt: 1 }}>
                    {formik.errors.pageType}
                  </FormLabel>
                )}
              </Stack>
            </Grid>
            {/* Title and Description */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Title</InputLabel>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  multiline
                  rows={4}
                />
              </Stack>
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="image">Upload Image</InputLabel>
                <Stack spacing={1}>
                  <UploadAvatar setFieldValue={formik.setFieldValue} file={existingImage} />
                  {formik.touched.files && formik.errors.files && (
                    <FormHelperText error style={{ color: 'red' }}>
                      {formik.errors.files as string}
                    </FormHelperText>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </>

          <Divider />

          <Grid item xs={12}>
            <Stack direction="row" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-end' }}>
              <Link to={''} onClick={() => navigate(-1)}>
                <Button variant="outlined" color="error" sx={{ mr: 1 }}>
                  Cancel
                </Button>
              </Link>
              <Button variant="contained" type="submit">
                {rowData ? 'Update' : 'Add'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default FeaturesCards;
