import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import { AddCircle, Edit, SearchNormal1, Trash, ElementPlus } from 'iconsax-react';
import { dispatch, useSelector } from 'store';
import { delusertype, setUserTypeSearch, resetStateusertype } from 'store/reducers/usertype';
import MainCard from 'components/MainCard';
import useAuth from 'hooks/useAuth';
import Loader2 from 'components/Loader2';
import IconButton from 'components/@extended/IconButton';
import Snackbar from 'utils/Snackbar';
import CustomTable from 'components/@extended/CustomTable';
import { FormattedMessage } from 'react-intl';
import SkeletonTable from 'components/SkeletonTable';
import { deleteContent, getContent, statusChange } from 'services/landingPageManagement.service';
import { useTabContext } from 'contexts/TabContext';

const LandingPageTable = ({ data }: any) => {
  const { user }: any = useAuth();
  const navigate = useNavigate();
  const pageIndex = useSelector((state: any) => state?.usertype)?.pageIndex;
  const search = useSelector((state: any) => state?.usertype)?.search;
  const size = useSelector((state: any) => state?.usertype)?.size;
  const [value, setValue] = useState<String | null>('');
  const [content, setContent] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [pageType, setPageType] = useState('homePage');

  const { handleTabChange } = useTabContext();

  const changeTab = (newTab: number) => {
    handleTabChange(newTab);
  };
  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(pageIndex(1));
    dispatch(size(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    dispatch(pageIndex(page));
  };
  useEffect(() => {
    fetchContent();
  }, [size, pageIndex, search, pageType]);

  useEffect(() => {
    return () => {
      dispatch(resetStateusertype());
    };
  }, [dispatch]);

  const handleSearch = () => {
    dispatch(setUserTypeSearch(value));
  };

  const fetchContent = () => {
    try {
      setLoading(true);
      const params =
        data === 'banner' || data === 'features' || data === 'faq'
          ? { type: data, pageType }
          : { type: data === 'contactUs' ? 'getInTouch' : data };
      getContent(params).then((result) => {
        setLoading(false);
        setContent(result?.data?.data?.data);
        setCount(result?.data?.data?.count);
      });
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.status === 403) {
        const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
        Snackbar(errorMessage, 'warning');
      } else {
        const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
        Snackbar(errorMessage, 'error');
      }
    }
  };

  const removeContent = (deleteId: string) => {
    setDisabled(true);
    deleteContent({ id: deleteId, type: data === 'contactUs' ? 'getInTouch' : data })
      .then((response) => {
        handleClose1();
        if (response?.status === 200) {
          dispatch(delusertype(deleteId));
          setCount(count - 1);
          Snackbar('Content Removed Successfully', 'success');
        } else {
          Snackbar(response.data.message, 'success');
        }
        setDisabled(false);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
        setDisabled(false);
      });
  };
  const columns = useMemo(() => {
    let base_columns = [
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Description',
        accessor: 'description'
      }
    ];
    if (['whyUs', 'features', 'experience'].includes(data)) {
      base_columns.push({
        Header: 'Cards/ Points Count',
        accessor: 'cards',
        Cell: ({ value }: { value: any }) => {
          // Ensure value is an array and get its length
          const cardsCount = Array.isArray(value) ? value?.length : 0;
          return (
            <div>
              <Chip variant="filled" label={cardsCount} size="small" sx={{ bgcolor: 'primary', borderRadius: 1 }} />
            </div>
          );
        }
      });
    }
    if (['features'].includes(data)) {
      base_columns.push({
        Header: 'content type',
        accessor: 'type',
        Cell: ({ value }: { value: any }) => {
          return (
            <div>
              <Chip variant="filled" label={value ? value : 'none' } size="small" sx={{ bgcolor: 'primary', borderRadius: 1 }} />
            </div>
          );
        }
      });
    }
    if (['contactUs'].includes(data)) {
      base_columns.push({
        Header: 'Contact Email',
        accessor: 'contactEmail'
      });
      base_columns.push({
        Header: 'Contact Number',
        accessor: 'contactNo'
      });
    }

    // if (['points'].includes(data)) {
    //   base_columns.push({
    //     Header: 'Points count',
    //     accessor: 'points',
    //     Cell: ({ value }: { value: any }) => {
    //       // Ensure value is an array and get its length
    //       const pointsCount = Array.isArray(value) ? value?.length : 0;
    //       return (
    //         <div>
    //           <Chip variant="filled" label={pointsCount} size="small" sx={{ bgcolor: 'primary', borderRadius: 1 }} />
    //         </div>
    //       );
    //     }
    //   });
    // }

    // Add the Options column last
    base_columns.push({
      Header: 'Status',
      accessor: 'isActive',
      Cell: ({ value, row }: { value: boolean; row: any }) => {
        const [isActive, setIsActive] = useState(value);
        const [disabled, setDisabled] = useState(false);
        const handleChange = () => {
          const _id = row.original._id;
          const updatedIsActive = !isActive;
          const userData = { id: _id, isActive: updatedIsActive, type: data === 'contactUs' ? 'getInTouch' : data };
          setIsActive(updatedIsActive);
          statusChange(userData)
            .then((res) => {
              if (res.status === 200) {
                setDisabled(false);
                Snackbar(res.data.message, 'success');
              } else {
                Snackbar(res.data.message, 'error');
                setIsActive(!updatedIsActive);
              }
            })
            .catch((err) => {
              setDisabled(false);
              setIsActive(!updatedIsActive);
              if (err?.response?.status === 403) {
                const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
                Snackbar(errorMessage, 'warning');
              } else {
                const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
                Snackbar(errorMessage, 'error');
              }
            });
        };

        return (
          <Switch
            checked={isActive}
            onChange={
              user?._id !== row.original._id
                ? handleChange
                : () => {
                    return false;
                  }
            }
            onClick={() => {
              setDisabled(true);
            }}
          />
        );
      }
    });
    base_columns.push({
      Header: 'Options',
      accessor: '',
      Cell: (row: any) => (
        <>
          <Stack direction="row" spacing={0}>
            <Tooltip title={<FormattedMessage id="Edit" />}>
              <span>
                <IconButton
                  color={'primary'}
                  onClick={() => {
                    navigate('/superadmin/landingpagemanagement/view', {
                      state: {
                        rowData: row.cell.row.original,
                        data: data === 'contactUs' ? 'getInTouch' : data,
                        typeData: data === 'contactUs' ? 'getInTouch' : data
                      }
                    });
                    changeTab(data);
                  }}
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="Delete" />}>
              <span>
                <IconButton
                  color={'error'}
                  onClick={() => {
                    handleOpen();
                    setDeleteId(row?.cell?.row?.original?._id);
                  }}
                >
                  <Trash />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      )
    });

    // Conditional columns for 'faq'
    if (data === 'faq') {
      return [
        {
          Header: 'question',
          accessor: 'question'
        },
        // {
        //   Header: 'Answers',
        //   accessor: 'answer'
        // },
        {
          Header: 'Status',
          Footer: 'Status',
          accessor: 'isActive',
          Cell: ({ value, row }: { value: boolean; row: any }) => {
            const [isActive, setIsActive] = useState(value);
            const [disabled, setDisabled] = useState(false);

            const handleChange = () => {
              const _id = row.original._id;
              const updatedIsActive = !isActive;
              const userData = { id: _id, isActive: updatedIsActive, type: data };
              setIsActive(updatedIsActive);
              statusChange(userData)
                .then((res) => {
                  if (res.status === 200) {
                    setDisabled(false);
                    Snackbar(res.data.message, 'success');
                  } else {
                    Snackbar(res.data.message, 'error');
                    setIsActive(!updatedIsActive);
                  }
                })
                .catch((err) => {
                  setDisabled(false);
                  setIsActive(!updatedIsActive);
                  if (err?.response?.status === 403) {
                    const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
                    Snackbar(errorMessage, 'warning');
                  } else {
                    const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
                    Snackbar(errorMessage, 'error');
                  }
                });
            };

            return (
              <Switch
                checked={isActive}
                onChange={
                  user?._id !== row.original._id
                    ? handleChange
                    : () => {
                        return false;
                      }
                }
                onClick={() => {
                  setDisabled(true);
                }}
              />
            );
          }
        },
        {
          Header: 'Options',
          accessor: '',
          Cell: (row: any) => (
            <>
              <Stack direction="row" spacing={0}>
                <Tooltip title={<FormattedMessage id="Edit" />}>
                  <span>
                    <IconButton
                      color={'primary'}
                      onClick={() => {
                        navigate('/superadmin/landingpagemanagement/view', {
                          state: { rowData: row.cell.row.original, data, typeData: data }
                        });
                        changeTab(data);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Delete" />}>
                  <span>
                    <IconButton
                      color={'error'}
                      onClick={() => {
                        handleOpen();
                        setDeleteId(row?.cell?.row?.original?._id);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            </>
          )
        }
      ];
    }
    if (data === 'useCase') {
      return [
        {
          Header: 'title',
          accessor: 'title'
        },
        // {
        //   Header: 'Answers',
        //   accessor: 'answer'
        // },
        {
          Header: 'Status',
          Footer: 'Status',
          accessor: 'isActive',
          Cell: ({ value, row }: { value: boolean; row: any }) => {
            const [isActive, setIsActive] = useState(value);
            const [disabled, setDisabled] = useState(false);

            const handleChange = () => {
              const _id = row.original._id;
              const updatedIsActive = !isActive;
              const userData = { id: _id, isActive: updatedIsActive, type: data };
              setIsActive(updatedIsActive);
              statusChange(userData)
                .then((res) => {
                  if (res.status === 200) {
                    setDisabled(false);
                    Snackbar(res.data.message, 'success');
                  } else {
                    Snackbar(res.data.message, 'error');
                    setIsActive(!updatedIsActive);
                  }
                })
                .catch((err) => {
                  setDisabled(false);
                  setIsActive(!updatedIsActive);
                  if (err?.response?.status === 403) {
                    const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
                    Snackbar(errorMessage, 'warning');
                  } else {
                    const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
                    Snackbar(errorMessage, 'error');
                  }
                });
            };

            return (
              <Switch
                checked={isActive}
                onChange={
                  user?._id !== row.original._id
                    ? handleChange
                    : () => {
                        return false;
                      }
                }
                onClick={() => {
                  setDisabled(true);
                }}
              />
            );
          }
        },
        {
          Header: 'Options',
          accessor: '',
          Cell: (row: any) => (
            <>
              <Stack direction="row" spacing={0}>
                <Tooltip title={<FormattedMessage id="Edit" />}>
                  <span>
                    <IconButton
                      color={'primary'}
                      onClick={() => {
                        navigate('/superadmin/landingpagemanagement/view', {
                          state: { rowData: row.cell.row.original, data, typeData: data }
                        });
                        changeTab(data);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Delete" />}>
                  <span>
                    <IconButton
                      color={'error'}
                      onClick={() => {
                        handleOpen();
                        setDeleteId(row?.cell?.row?.original?._id);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            </>
          )
        }
      ];
    }

    if (data === 'clients') {
      return [
        {
          Header: 'title',
          accessor: 'title'
        },
        {
          Header: 'Image',
          accessor: 'image',
          Cell: ({ value }) => (
            <img
              src={process.env.REACT_APP_FILE_URL + value?.path}
              alt="client"
              style={{ width: 'auto', height: 'auto', borderRadius: '8px', objectFit: 'cover' }}
            />
          )
        },
        {
          Header: 'Status',
          Footer: 'Status',
          accessor: 'isActive',
          Cell: ({ value, row }: { value: boolean; row: any }) => {
            const [isActive, setIsActive] = useState(value);
            const [disabled, setDisabled] = useState(false);

            const handleChange = () => {
              const _id = row.original._id;
              const updatedIsActive = !isActive;
              const userData = { id: _id, isActive: updatedIsActive, type: data };
              setIsActive(updatedIsActive);
              statusChange(userData)
                .then((res) => {
                  if (res.status === 200) {
                    setDisabled(false);
                    Snackbar(res.data.message, 'success');
                  } else {
                    Snackbar(res.data.message, 'error');
                    setIsActive(!updatedIsActive);
                  }
                })
                .catch((err) => {
                  setDisabled(false);
                  setIsActive(!updatedIsActive);
                  if (err?.response?.status === 403) {
                    const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
                    Snackbar(errorMessage, 'warning');
                  } else {
                    const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
                    Snackbar(errorMessage, 'error');
                  }
                });
            };

            return (
              <Switch
                checked={isActive}
                onChange={
                  user?._id !== row.original._id
                    ? handleChange
                    : () => {
                        return false;
                      }
                }
                onClick={() => {
                  setDisabled(true);
                }}
              />
            );
          }
        },
        {
          Header: 'Options',
          accessor: '',
          Cell: (row: any) => (
            <>
              <Stack direction="row" spacing={0}>
                <Tooltip title={<FormattedMessage id="Edit" />}>
                  <span>
                    <IconButton
                      color={'primary'}
                      onClick={() => {
                        navigate('/superadmin/landingpagemanagement/view', {
                          state: { rowData: row.cell.row.original, data, typeData: data }
                        });
                        changeTab(data);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Delete" />}>
                  <span>
                    <IconButton
                      color={'error'}
                      onClick={() => {
                        handleOpen();
                        setDeleteId(row?.cell?.row?.original?._id);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            </>
          )
        }
      ];
    }

    return base_columns;
  }, [data, user]);

  const handleClose1 = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await removeContent(id);
      // Filter out the deleted item from the table data
      const updatedData = content.filter((item: { _id: string }) => item._id !== id);
      setContent(updatedData); // Update the state with the new data
      Snackbar('Item deleted successfully', 'success');
    } catch (error) {
      Snackbar('Error deleting item', 'error');
    }
  };
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose1} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>delete</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to Remove the content?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleClose1}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                // removeContent(deleteId);
                handleDelete(deleteId);
                handleClose1();
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <MainCard
        sx={{
          px: 2
        }}
        title={
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5">
              <FormattedMessage id={`${capitalizeFirstLetter(data)} List`} />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage id="List of different Contents." />
            </Typography>
          </span>
        }
        secondary={
          <div style={{ marginRight: '20px' }}>
            <Tooltip title={<FormattedMessage id="Add Content" />}>
              <span>
                <IconButton
                  color="primary"
                  variant="dashed"
                  // disabled={count > 5}
                  onClick={() => {
                    navigate('/superadmin/landingpagemanagement/view', { state: { typeData: data === 'contactUs' ? 'getInTouch' : data } });
                    changeTab(data);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        }
        content={false}
      >
        <>
          {isLoading ? (
            <SkeletonTable columns={columns} />
          ) : (
            <>
              {disabled && (
                <div
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    // background: 'rgba(10, 10, 10, 0.7)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1
                    // marginTop: '12px'
                  }}
                >
                  <Loader2 />
                </div>
              )}
              {(data === 'banner' || data === 'faq') && (
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel id="pageType-select-label">Page Type</InputLabel>
                  <Select
                    labelId="pageType-select-label"
                    id="pageType-select"
                    value={pageType}
                    onChange={(e) => setPageType(e.target.value)}
                    label="Page Type"
                  >
                    <MenuItem value="homePage">Home Page</MenuItem>
                    <MenuItem value="contactUs">Contact Us</MenuItem>
                    <MenuItem value="overview">Overview</MenuItem>
                    <MenuItem value="features">Features</MenuItem>
                    <MenuItem value="mobileApp">Mobile App</MenuItem>
                  </Select>
                </FormControl>
              )}
              {data === 'features' && (
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel id="pageType-select-label">Page Type</InputLabel>
                  <Select
                    labelId="pageType-select-label"
                    id="pageType-select"
                    value={pageType}
                    onChange={(e) => setPageType(e.target.value)}
                    label="Page Type"
                  >
                    <MenuItem value="homePage">Home Page</MenuItem>
                    <MenuItem value="overview">Overview</MenuItem>
                    <MenuItem value="mobileApp">Mobile App</MenuItem>
                  </Select>
                </FormControl>
              )}
              <Box
                sx={{
                  height: 'auto',
                  overflow: 'hidden',
                  filter: disabled ? 'grayscale(100%) blur(3px)' : 'none'
                }}
              >
                <CustomTable
                  icon={ElementPlus}
                  text={<FormattedMessage id="No Content to display" />}
                  // link="/superadmin/landingpagemanagement/"
                  sortArray={[]}
                  columns={columns}
                  data={content || []}
                  sort={[]}
                  setSortingData={null}
                  notFound={notFound}
                  // search={search}
                  // count={count}
                  // size={size}
                  // pageIndex={pageIndex}
                  // handlePageChange={handlePageChange}
                  // handleSizeChange={handleChange}
                />
              </Box>
            </>
          )}
        </>
      </MainCard>
    </>
  );
};

export default LandingPageTable;
