import { Chip, Stack } from '@mui/material';
import { Briefcase } from 'iconsax-react';
import { useState } from 'react';
import { AIChatEvents, socket } from 'utils/socket';

const ChatOptionChip = ({ setMessages }: { setMessages: (data: { message: string; type: 'user' | 'bot' }[]) => void }) => {
  const [completedAction, setCompleteAction] = useState<string[]>([]);
  const handleChipQuestions = (message: string) => {
    socket.emit(AIChatEvents.CHAT_BOT_QUESTION_ANSWER, { message }, (data: any) => {
      console.log(data?.result?.replyMessage);
      setMessages([{ message: data?.result?.replyMessage, type: 'bot' }]);
    });
  };
  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      {completedAction.length === 0 && (
        <>
          <Chip
            color="primary"
            variant="outlined"
            onClick={() => {
              setCompleteAction((prev) => [...prev, 'contactUs']);
              handleChipQuestions('contactUs');
              setMessages([{ message: 'contactUs', type: 'user' }]);
            }}
            onDelete={() => {}}
            size="medium"
            label={'contactUs'}
            deleteIcon={<Briefcase variant="Bold" />}
          />
          <Chip
            color="primary"
            variant="outlined"
            onClick={() => {
              setCompleteAction((prev) => [...prev, 'features']);
              handleChipQuestions('features');
              setMessages([{ message: 'features', type: 'user' }]);
            }}
            onDelete={() => {}}
            size="medium"
            label={'Our Features'}
            deleteIcon={<Briefcase variant="Bold" />}
          />
        </>
      )}
    </Stack>
  );
};

export default ChatOptionChip;
