// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project-imports
import auth from './auth';
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import cartReducer from './cart';
import kanban from './kanban';
import invoice from './invoice';
import users from './users';
import authSlice from './authSlice';
import categorySlice from './categorySlice';
import contestSlice from './contest';
import courseSlice from './courseSlice';
import subscribedCourseSlice from './subscribedCourseSlice';
import notes from './notes';
import offers from './offer';
import currentContentSlice from './currentContentSlice';
import certificateSlice from './certificate';
import EmailTemplateSlice from './emailtemplate';
import notifySlice from './notify';
import blogSlice from './blog';
import organizationSlice from './organizationSettings';
import assessment from './assessment';
import history from './history';
import assessmentreport from './assessmentreport';
import userquiz from './userquiz';
import discussion from './discussion';
import group from './group';
import userreport from './userreport';
import coursereport from './coursereport';
import usercourseslist from './usercourseslist';
import usercertificate from './usercertificate';
import courseassessmentlist from './courseassessmentlist';
import usertype from './usertype';
import badgelist from './badgelist';
import superadmin from './superadmin';
import courseReview from './coursereview';
import notification from './notification';
import packagelist from './packagelist';
import categoryview from './categoryview';
import eventnotificationview from './eventnotificationview';
import offerview from './offerview';
import groupsview from './groupsview';
import organizationsdata from './organizationsdata';
import subscriptions from './subscriptions';
import assignpackage from './assignpackage';
import allorgrevenue from './allorgrevenue';
import superadminuser from './superadminuser';
import feedbackview from './feedbackview';
import feedback from './feedback';
import announcementForm from './announcementForm';
import questionbank from './questionbank';
import contentmanagement from './contentmanagement';
import contactlist from './contactlist';
import custompage from './custompage';
import exam from './exam';
import examattend from './examattend';
import resultdetails from './resultdetails';
import timeTable from './timeTable';
import ticket from './ticket';
import groupUser from './groupUser';
import addQuestionsBank from './addQuestionsBank';
import resulttable from './resulttable';
import examDeviceTest from './examDeviceTest';
import assignment from './assignment';
import classRoom from './classRoom';
import notifyfaculty from './notifyfaculty';
import allresult from './allresult';
import examUserList from './examUserList';
import coursereassign from './coursereassign';
import subjectlist from './subjectlist';
import customNotification from './customNotification';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  authe: authSlice,
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  kanban,
  superAdmin: superadmin,
  invoice,
  users,
  notification: notification,
  courseReview: courseReview,
  notifyMessage: notifySlice,
  organization: organizationSlice,
  contests: contestSlice,
  category: categorySlice,
  course: courseSlice,
  subscribedCourses: subscribedCourseSlice,
  notes,
  offers,
  currentContent: currentContentSlice,
  certificate: certificateSlice,
  blogs: blogSlice,
  EmailTemplate: EmailTemplateSlice,
  assessment: assessment,
  history: history,
  assessmentreport: assessmentreport,
  userquiz: userquiz,
  discussion: discussion,
  group: group,
  userreport: userreport,
  coursereport: coursereport,
  usercourseslist: usercourseslist,
  usercertificate: usercertificate,
  courseassessmentlist: courseassessmentlist,
  usertype: usertype,
  badgelist: badgelist,
  packagelist: packagelist,
  categoryview: categoryview,
  eventnotificationview: eventnotificationview,
  offerview: offerview,
  groupsview: groupsview,
  organizationsdata: organizationsdata,
  subscriptions: subscriptions,
  assignpackage: assignpackage,
  allorgrevenue: allorgrevenue,
  superadminuser: superadminuser,
  feedbackview: feedbackview,
  feedback: feedback,
  announcementForm: announcementForm,
  questionbank: questionbank,
  contentmanagement: contentmanagement,
  contactlist: contactlist,
  custompage: custompage,
  exam: exam,
  examattend: examattend,
  resultdetails: resultdetails,
  ticket: ticket,
  timeTable: timeTable,
  groupUser: groupUser,
  addQuestionsBank: addQuestionsBank,
  resulttable: resulttable,
  examDeviceTest: examDeviceTest,
  assignment: assignment,
  notifyfaculty: notifyfaculty,
  allresult: allresult,
  coursereassign: coursereassign,
  subjectlist: subjectlist,
  classRoom,
  examUserList,
  customNotification
});

export default reducers;
